@import "../../styles/Variable.scss";
.loginleft {
  // background-image: url(../../assets/images/login-left.png);
  height: 100%;
  width: 100%;
  padding: 0 60px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loginright {
  // background-image: url(../../assets/images/loginright.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.inner {
  backdrop-filter: blur(30px);
  border-radius: 15px;
  padding: 30px;
  margin: 0 auto;
  width: 450px;
  background-color: rgba($color: $white, $alpha: 0.5);
}

.resident-head {
  text-align: left;
  margin-bottom: 20px;
  h2 {
    font-family: "Product Sans Bold";
    span {
      color: $primary;
      font-family: "Product Sans Bold";
    }
  }
}
.userloginright {
  background-image: url(../../assets/images/userloginright.png);
  height: 100vh;
  width: 100%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.userloginleft {
  padding: 40px 80px;
  background-color: $white;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  .back {
    position: absolute;
    top: 30px;
    left: 30px;
  }
}
.logo-user {
  margin-bottom: 20px;
  img {
    width: 150px;
  }
}

form {
  width: 100%;
}

.logo-left-set {
  width: 180px;
  margin-bottom: 30px;
}

.loginright {
  h2 {
    font-size: 20px;
  }

  .loginright-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    .heading {
      h2 {
      }
    }
    .download-btn {
      button {
        display: flex;
        align-items: center;
        background-color: #000;
        border-radius: 10px;
        font-size: 14px;
        line-height: 18px;
        padding: 8px 12px;
        color: #fff;
        border: 0px;
        outline: none;
        box-shadow: none;
        white-space: nowrap;
        svg {
          fill: #fff;
          width: 14px;
          height: 14px;
          min-width: 14px;
          margin-left: 8px;
        }
        &:hover {
          background-color: #159fff;
        }
      }
    }
  }
  .seprator {
    position: relative;
    text-align: center;
    margin: 10px 0px;
    &::after {
      content: "";
      background-color: #7f7f7f;
      position: absolute;
      left: 0;
      width: 100%;
      height: 1px;
      top: 12px;
      left: 0;
    }
    span {
      color: #000;
      font-size: 14px;
      display: inline-block;
      backdrop-filter: blur(30px);
      position: relative;
      z-index: 1;
      padding: 0px 5px;
      background-color: rgba(255, 255, 255, 0.01);
    }
  }

  //file-select-btn
  .file-select-btn {
    background-color: #ffffff;
    border-radius: 15px;
    font-size: 14px;
    display: flex;
    align-items: center;
    overflow: hidden;
    .select-text {
      width: calc(100% - 80px);
      padding: 15px;
      svg {
        width: 16px;
        height: 16px;
        min-width: 16px;
        margin-right: 10px;
      }
      span {
        color: #000000;
        opacity: 0.3;
      }
    }
    .file-btn {
      position: relative;
      display: inline-block;
      button {
        background-color: #7f7f7f;
        padding: 18px 10px;
        color: #fff;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        border: 0px;
        outline: none;
        border-radius: 0px 6px 6px 0px;
        min-width: 80px;
      }
      input {
        position: absolute;
        top: 0;
        left: 0px;
        width: 100%;
        height: 100%;
        opacity: 0;
      }
    }
  }
}
