$primary: #159fff;
$secondary: #0d0d0d;
$sucess: #06aa00;
$warning: #ffb100;
$danger: #e31f19;
$black: #000000;
$white: #ffffff;
$lightpurple: #f1f4fd;
$lightGreen: #c5d93a;
$grey: #bfbfbf;
