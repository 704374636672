.residents-page {
  .custom-table {
    &.table {
      tr {
        td {
          .form-group {
            margin-bottom: 0px;
          }
        }
      }
    }
  }
}

.see-more-align {
  svg {
    width: 20px;
    height: 20px;
    object-fit: contain;
  }
}

.select-margin {
  margin-right: 20px;
  .form-group {
    margin-right: 30px;
    margin-bottom: 0;
    width: 100%;
    .form-select {
      height: 53px;
    }
  }
}
