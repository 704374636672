@import "../../styles/Variable.scss";

.header {
  // padding: 20px;
  ul {
    li {
      list-style: none;
      padding: 0px 10px;
      color: rgba($color: $secondary, $alpha: 0.5);
      &.active {
        color: $secondary;
        font-weight: 600;
      }
    }
  }
}
