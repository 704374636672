@import "../../styles/Variable.scss";

.loading-main {
  img {
    width: 40px;
    height: 40px;
  }
  .loading-main-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    .spinner-border {
      margin-right: 10px;
      color: $white;
    }
    h6 {
      color: $white;
      font-size: 14px;
      margin: 0px;
    }
  }
}
