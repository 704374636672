@import "./Variable.scss";

@font-face {
  font-family: "Product Sans Bold";
  src: url("../assets//font/product-sans/ProductSans-Bold.woff2")
      format("woff2"),
    url("../assets/font/product-sans/ProductSans-Bold.woff") format("woff");

  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Product Sans Bold Italic";
  src: url("../assets/font/product-sans/ProductSans-BoldItalic.woff2")
      format("woff2"),
    url("../assets/font/product-sans/ProductSans-BoldItalic.woff")
      format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Product Sans Regular";
  src: url("../assets/font/product-sans/ProductSans-Regular.woff2")
      format("woff2"),
    url("../assets/font/product-sans/ProductSans-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Product Sans";
  src: url("../assets/font/product-sans/ProductSans-Regular.woff2")
      format("woff2"),
    url("../assets/font/product-sans/ProductSans-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Product Sans Italic";
  src: url("../assets/font/product-sans/ProductSans-Italic.woff2")
      format("woff2"),
    url("../assets/font/product-sans/ProductSans-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

* {
  font-family: "Product Sans";
}
body {
  background-color: $lightpurple;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0px;
}
h1 {
  font-size: 44px;
  //mobile media query
  @media screen and (max-width: 767px) {
    font-size: 28px;
  }

  //tablet media query
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 32px;
  }

  //laptop media query
  @media (min-width: 992px) and (max-width: 1200px) {
    font-size: 36px;
  }
}

h2 {
  font-size: 30px;
  //mobile media query
  @media screen and (max-width: 767px) {
    font-size: 18px;
  }

  //tablet media query
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 22px;
  }

  //laptop media query
  @media (min-width: 992px) and (max-width: 1200px) {
    font-size: 26px;
  }
}

h3 {
  font-size: 20px !important;
}

h4 {
  font-size: 18px !important;
}

h5 {
  font-size: 16px !important;
}
p {
  font-size: 16px;
  @media screen and (max-width: 767px) {
    font-size: 12px;
  }
}
a {
  cursor: pointer;
  text-decoration: none !important;
}
//bold
.fw-bold {
  font-weight: 600;
}
//font
.font12 {
  font-size: 12px;
}
.font30 {
  font-size: 30px;
}
.font25 {
  font-size: 25px;
}
.font40 {
  font-size: 40px;
}
//color
.white-color {
  color: $white;
}
.theme-color {
  color: $primary;
}
.sucess-color {
  color: $sucess;
}
.danger-color {
  color: $danger;
}
.grey-color {
  color: rgba($color: #000000, $alpha: 0.5);
}
.password-bar-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  p {
    white-space: nowrap;
  }
}

.colorDanger {
  color: #ff0000;
}

.colorWarning {
  // color: #e49606;
  color: #5493db;
}

.colorGood {
  color: #e49606;
}

.colorStrong {
  color: #008000;
}

.colorPrimary {
  color: $primary;
}

.word-break {
  word-break: break-all;
}

.color-indicators {
  display: flex;
  align-items: center;
  width: 100%;
  span {
    display: inline-block;
    width: 24%;
    height: 4px;
    margin: 1px 2px;

    &:nth-child(1) {
      background-color: #ff0000;
    }
    &:nth-child(2) {
      background-color: #5493db;
    }
    &:nth-child(3) {
      background-color: #e49606;
    }

    &:nth-child(4) {
      background-color: #008000;
    }
  }
}
.h53 {
  height: 53px;
}

.add-keys-btn {
  border-radius: 100px;
  width: 30px;
  height: 30px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

.range-set-input {
  .form-group {
    width: 100%;
    label {
      display: none;
    }
    .form-control {
      border-radius: 0 !important;
    }
  }
  input {
    text-align: center;
  }
  button {
    padding: 14px 20px;
    transition: 0.3s all;
    svg {
      margin: 0;
      width: 20px;
      height: 20px;
      min-width: 20px;
      fill: $primary;
    }
    &:hover {
      svg {
        fill: $secondary;
      }
    }
  }
  .left {
    border-radius: 10px 0 0 10px;
    border-right: none !important;
  }
  .right {
    border-radius: 0 10px 10px 0;
    border-left: none !important;
  }
}

.item-center {
  display: flex;
  justify-content: center;
}
//border
.bd-bottom {
  padding-bottom: 10px;
  border-bottom: 1px solid rgba($color: #000000, $alpha: 0.2);
}

.info-icon {
  text-align: right;
  top: 10px;
  right: 10px;
}
.info-icon > span {
  color: $white;
  font-size: 18px;
  line-height: normal;
  i {
  }
}
// loader
.loader {
  border: 16px solid #f9f4f4;
  border-radius: 50%;
  border-top: 16px solid #f8f4f4;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}
.theme-button {
  border: 1px solid transparent;
  // background-color: transparent;
  padding: 15px 30px;
  font-size: 16px;
  border-radius: 10px;
  margin-right: 5px;
  white-space: nowrap;
  text-align: center;

  @media screen and (max-width: 767px) {
    font-size: 14px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 12px;
  }
  @media (min-width: 992px) and (max-width: 1200px) {
    font-size: 12px;
    padding: 11px 20px;
  }
  img {
    margin-right: 10px;
    width: 40px;
    min-width: 40px;
    height: 40px;
    border-radius: 100px;
    overflow: hidden;
  }
  i {
    margin-right: 5px;
    font-size: 20px;
  }
  &:focus {
    outline: none;
    box-shadow: none;
  }
  &.btn-sm {
    padding: 8px 20px;
    font-size: 14px;
  }
  &.btn-xs {
    padding: 4px 10px;
    font-size: 12px;
  }

  &.primary-btn {
    border-color: $primary;
    background-color: $primary;
    color: $white;
    &:hover {
      background-color: $black;
      color: $white;
      border-color: $black;
    }
  }

  &.primary-btn-outline {
    border-color: $primary;
    background-color: $white;
    color: $primary;
    &:hover {
      background-color: $black;
      color: $white;
      border-color: $black;
    }
  }

  &.black-btn-outline {
    border-color: $secondary;
    background-color: transparent;
    color: $secondary;
  }
  &.black-btn {
    border-color: $secondary;
    background-color: $secondary;
    color: $white;
  }
  &.white-btn-outline {
    border-color: $white;
    background-color: transparent;
    color: $white;
  }

  &.trans-btn {
    border-color: transparent;
    background-color: transparent;
    border: none;
    padding: 0px;
  }
  &.btn-lg {
    width: 250px;
    max-width: 250px;
  }
  &.grey-btn-sm {
    background-color: rgba($color: $secondary, $alpha: 0.1);
    border-color: transparent;
    border: none;
    padding: 4px 10px;
    color: rgba($color: $secondary, $alpha: 0.5);
  }
  &.success-btn {
    background-color: $sucess;
    border-radius: 20px;
    color: $white;
    padding: 5px 15px;
  }
  &.danger-btn {
    background-color: $danger;
    border-radius: 20px;
    color: $white;
    padding: 5px 15px;
  }
  &.white-hv {
    &:hover {
      background-color: $white;
      color: $primary;
    }
  }
  &.grey-outline-btn {
    background-color: $white;
    border: 1px solid $grey;
    color: $grey;
    // &:hover {
    //   background-color: $black;
    // }
  }
}

.border-seprate {
  border: 1px solid $grey;
  padding: 20px 0;
  border-radius: 20px;
}

.delete-floor-icon {
  text-align: right;
  margin-bottom: 20px;
  padding-right: 20px;
  svg {
    cursor: pointer;
    width: 20px;
    height: 20px;
    &:hover {
      fill: $danger;
    }
  }
}

// form input
.form-group {
  margin-bottom: 15px;
  label {
    color: $black;
    margin: 0px 0px 6px;
    display: block;
    //   font-weight: $regular;
    font-size: 18px;
    @media screen and (max-width: 1366px) and (min-width: 768px) {
      font-size: 14px;
    }
    @media only screen and (max-width: 767px) {
      font-size: 12px;
    }
    &:empty {
      display: none;
    }
  }

  .form-select {
    font-size: 18px;
    background-color: $white;
    border-radius: 15px;
    // padding: 15px 15px 15px 30px;
    padding: 8px 15px;
    outline: none;
    box-shadow: none;
    border: 1px solid #0d0d0d4d;
    min-width: 130px;
    // width: fit-content;
    &.theme-select {
      background-color: rgba($color: $primary, $alpha: 0.1);
      color: $primary;
    }
    &.approve {
      background-color: $sucess;
      color: $white;
      option {
        background-color: $white;
      }
    }
    &.denied {
      background-color: $danger;
      color: $white;
      option {
        background-color: $white;
      }
    }
    &.pending {
      background-color: $grey;
      color: $white;
      option {
        background-color: $white;
      }
    }
    &.inprogress {
      background-color: $grey;
      color: $white;
      option {
        background-color: $white;
      }
    }
    &:focus {
      outline: none;
      box-shadow: none;
    }
    &::placeholder {
      color: rgba($color: $black, $alpha: 0.3);
      //   opacity: 0.3;
    }
    &.custom-select-multi {
      padding: 0px;
      border: 0px;
    }
  }
  .form-control {
    font-size: 18px;
    background-color: $white;
    border-radius: 15px;
    padding: 15px 15px 15px 30px;
    outline: none;
    box-shadow: none;
    border: none;
    font-size: 14px;
    &.grey-input {
      border: 1.5px solid #0d0d0d4d;
    }
    &::placeholder {
      color: rgba($color: $black, $alpha: 0.3);
      //   opacity: 0.3;
    }
    &:focus {
      outline: none;
      box-shadow: none;
    }
    // @media screen and (max-width: 1366px) and (min-width: 768px) {
    //   font-size: 14px;
    // }
    @media only screen and (max-width: 767px) {
      font-size: 12px;
    }
  }

  .inline-label-input {
    display: flex;
    align-items: center;
    label {
      margin-right: 10px;
      white-space: nowrap;
    }
  }

  .form-select {
    background-size: 12px 12px;
    outline: none;
    box-shadow: none;
  }

  textarea {
    resize: none;
  }

  //right-icon-input
  .right-icon-input {
    position: relative;
    .form-control {
      padding-right: 40px;
    }
    span {
      position: absolute;
      top: 14px;
      right: 20px;
      z-index: 2;
      // ion-icon {
      //   color: $secondary;
      //   font-size: 18px;
      //   @media screen and (max-width: 1366px) and (min-width: 768px) {
      //     font-size: 18px;
      //   }
      //   @media only screen and (max-width: 767px) {
      //     font-size: 14px;
      //   }
      // }

      // ion-img {
      //   width: 18px;
      //   @media screen and (max-width: 1366px) and (min-width: 768px) {
      //     width: 14px;
      //   }
      //   @media only screen and (max-width: 767px) {
      //     width: 12px;
      //   }
      // }

      svg {
        height: 20px;
        @media screen and (max-width: 1366px) and (min-width: 768px) {
          height: 18px;
        }
        @media only screen and (max-width: 767px) {
          height: 12px;
        }
      }
    }
  }

  //left-icon-input
  .left-icon-input {
    position: relative;
    .form-control,
    .form-select {
      padding-left: 65px;
    }
    span {
      position: absolute;
      top: 15px;
      left: 30px;
      z-index: 2;
      svg {
        fill: #b6b6b6;
      }
      i {
        //   color: $secondary;
        font-size: 18px;
        @media screen and (max-width: 1366px) and (min-width: 768px) {
          font-size: 18px;
        }
        @media only screen and (max-width: 767px) {
          font-size: 14px;
        }
      }

      img,
      svg {
        width: 18px;
        height: 18px;
        @media screen and (max-width: 1366px) and (min-width: 768px) {
          width: 14px;
          height: 14px;
        }
        @media only screen and (max-width: 767px) {
          width: 12px;
          height: 12px;
        }
      }
    }
  }

  //double-icon-input
  .double-icon-input {
    position: relative;
    .form-control {
      padding-left: 65px;
      padding-right: 65px;
    }
    span {
      position: absolute;
      z-index: 2;
      i {
        color: $secondary;
        font-size: 18px;
        @media screen and (max-width: 1366px) and (min-width: 768px) {
          font-size: 18px;
        }
        @media only screen and (max-width: 767px) {
          font-size: 12px;
        }
      }
      img,
      svg {
        width: 18px;
        @media screen and (max-width: 1366px) and (min-width: 768px) {
          width: 14px;
        }
        @media only screen and (max-width: 767px) {
          width: 12px;
        }
      }
      &.left-icon {
        top: 13px;
        left: 30px;
      }
      &.right-icon {
        top: 12px;
        right: 15px;
      }
    }
  }

  //search-bar
  .custom-search {
    background: $white !important;
    border: 1px solid $primary !important;
    border-radius: 20px !important;

    &:focus {
      outline: none !important;
      box-shadow: none !important;
    }
    &::placeholder {
      color: rgba($color: $primary, $alpha: 0.3);
    }
    svg {
      fill: $primary;
    }
  }
  //image-input
  .image-input {
    &.left-image-input {
      position: relative;
      .form-control {
        padding-left: 65px;
      }
      .circle-img {
        position: absolute;
        top: 6px;
        left: 10px;
        border: 1px solid $secondary;
        border-radius: 100%;
        margin-right: 0px;
      }
    }
  }
}
//opacity
.op-3 {
  opacity: 0.3;
}
.op-6 {
  opacity: 0.6;
}
//custom-checkbox

.outer-check {
  display: block;
  position: relative;
  // padding-left: 35px;
  // margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &.login-check {
    padding-left: 35px;
    margin-bottom: 12px;
    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  &.white-checkbox {
    .checkmark {
      border-color: $white;
    }
    input:checked ~ .checkmark {
      border-color: $white;
    }
    .checkmark:after {
      border-color: $white;
    }
  }
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    // position: absolute;
    // top: 0;
    // left: 0;
    height: 25px;
    width: 25px;
    border: 2px solid black;
    border-radius: 50%;
  }

  // &:hover input ~ .checkmark {
  //   background-color: #ccc;
  // }

  input:checked ~ .checkmark {
    border: 2px solid $primary;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  input:checked ~ .checkmark:after {
    display: block;
  }

  .checkmark:after {
    left: 10px;
    top: 7px;
    width: 5px;
    height: 10px;
    border: solid $primary;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

.main {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  .main-box {
    width: calc(100% - 300px);
    margin-left: 300px;
  }
}

.admin-card {
  border-radius: 20px;
  overflow: hidden;
  background-color: $white;
  position: relative;
  .card-top {
    img {
      height: 200px;
      object-fit: cover;
      object-position: center;
      max-height: 200px;
    }
  }
  // .online {
  //   height: 8px;
  //   width: 8px;
  //   border-radius: 50%;
  //   background-color: $sucess;
  //   position: absolute;
  //   bottom: 29px;
  //   left: 44px;
  // }
  // .offline {
  //   height: 8px;
  //   width: 8px;
  //   border-radius: 50%;
  //   background-color: $danger;
  //   position: absolute;
  //   bottom: 29px;
  //   left: 164px;
  // }
}

.white-card {
  background-color: $white;
  padding: 20px;
  border-radius: 20px;
  // height: 100%;
  // min-height: 100px;
  p {
    word-break: break-all;
  }
  &.white-height-card {
    height: 100%;
  }
  .contact-sensor-unit{
    h4{
      margin: 0;
      font-size: 18px;
      color: $black;
      padding-bottom: 2px;
      font-weight: 500;
      text-transform: capitalize;
    }
    p{
      font-size: 16px;
      color: $secondary;
      margin: 0;
      text-transform: capitalize;
    }
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.upload-area {
  border: 2px dashed #0d0d0d4d;
  border-radius: 20px;
  padding: 40px;
  position: relative;
  .file-op {
    opacity: 0;
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .upload {
    height: 300px;
    width: auto;
    @media screen and (max-width: 767px) {
      height: 100px;
    }
  }
}

.shadow-card {
  box-shadow: 0px 0px 20px #00000014;
  cursor: pointer;
}
.online-outer {
  position: relative;
  h5 {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .online-lg {
    height: 18px;
    width: 18px;
    border-radius: 50%;
    background-color: $sucess;
    display: inline-block;
    margin-right: 10px;
  }
}
.offline-outer {
  position: relative;
  h5 {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .offline-lg {
    height: 18px;
    width: 18px;
    border-radius: 50%;
    background-color: $danger;
    margin-right: 10px;
    display: inline-block;
  }
}
.custom-table {
  // border-collapse: unset;
  text-align: center;
  position: relative;
  &.table {
    thead {
      position: sticky;
      width: 100%;
      top: 0;
      left: 0;
      tr {
        td {
          vertical-align: middle;
          color: $white;
          &:nth-child(1) {
            border-left: 0px !important;
          }
          &:nth-last-child(1) {
            border-right: 0px !important;
          }
          .down-icon {
            width: 15px;
            height: 15px;
            margin-left: 3px;
          }
        }
      }

      tr {
        td {
          text-align: left;
          background-color: $primary;
          color: $white;
          padding: 20px;
          white-space: nowrap;
          span {
            display: block;
          }
          &.bd-top-radius-left {
            border-top-left-radius: 10px;
          }
          &.bd-top-radius-right {
            border-top-right-radius: 10px;
          }
        }
      }
    }
    tbody {
      //border-collapse: collapse !important;
      border-top: 0px;
      tr {
        // td {
        //   &:first-child {
        //     font-weight: 600;
        //   }
        // }
        border: none;
        &:nth-child(odd) {
          td {
            background-color: rgba($color: $primary, $alpha: 0.06);
          }
        }
        &:nth-child(even) {
          td {
            background-color: rgba($color: $white, $alpha: 0.6);
          }
        }
        &:last-child {
          td {
            &:first-child {
              border-radius: 0px 0px 0px 20px;
            }
            &:last-child {
              border-radius: 0px 0px 20px 0px;
            }
          }
        }
        td {
          border: none;
          padding: 10px 20px;
          text-align: left;
          vertical-align: middle;
          img {
            height: 30px;
            width: 30px;
            border: 1px solid $secondary;
          }
          span {
            display: block;
            text-decoration: line-through;
          }
          &.bd-bottom-radius-left {
            border-bottom-left-radius: 10px;
          }
          &.bd-bottom-radius-right {
            border-bottom-right-radius: 10px;
          }
        }
      }
    }
  }
}
.otp {
  input {
    height: 50px;
    width: 50px !important;
    border-radius: 15px;
    max-width: 50px;
    margin-right: 10px;
    border: none;
    outline: none;
    text-align: center;
    &:focus-visible,
    &:focus {
      border: none;
    }
  }
}
.form-check-input {
  &:focus {
    outline: none;
    box-shadow: none;
  }
  &:checked {
    background-color: $sucess;
    border-color: $sucess;
  }
}
.online {
  height: 8px;
  width: 8px;
  min-width: 8px;
  border-radius: 50%;
  background-color: $sucess;
}
.online-lg {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: $sucess;
}
.offline {
  height: 8px;
  width: 8px;
  min-width: 8px;
  border-radius: 50%;
  background-color: $danger;
}
.offline-lg {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: $danger;
}
.grey-lg {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: #00000080;
}

//custom-radio
/* The container */
.custom-radio {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.custom-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

/* Create a custom radio button */
.custom-radio .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  border: 2px solid black;
  background-color: transparent;
  border-radius: 50%;
}

/* When the radio button is checked, add a blue background */
.custom-radio input:checked ~ .checkmark {
  background-color: transparent;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.custom-radio .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.custom-radio input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.custom-radio .checkmark:after {
  top: 6px;
  left: 6px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: black;
}

.inline-radio {
  display: flex;
  align-items: center;
  gap: 20px;
}

.react-date-picker__wrapper {
  border: none !important;
}

.error {
  color: $danger;
  // padding-left: 15px;
  font-size: 12px !important;
  text-align: left;
  padding-left: 25px;
  i {
    color: $danger;
  }
}
.btn-close {
  background: none;
  opacity: 1;
}

.theme-border {
  width: 50px;
  min-width: 50px;
  height: 50px;
  border: 1px solid $primary;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  border-radius: 20px;
}

.grey-btn-sm {
  background: transparent;
  border-radius: 7px;
  border: 1px solid rgba($color: $secondary, $alpha: 0.5);
  height: 25px;
  width: fit-content;
  font-size: 12px;
  &.active {
    background-color: $primary;
    color: $white;
    border-color: $primary;
  }
}

.bd-secondary {
  border: 1px solid rgba($color: $secondary, $alpha: 0.3);
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.top-right-icon {
  position: absolute;
  right: 25px;
  top: 25px;
  margin: 0px;
}

.fix-img {
  height: 120px;
  width: 120px;
  border-radius: 50%;
  max-width: 120px;
  max-height: 120px;
  object-fit: cover;
  object-position: center;
}

.status-table {
  border-radius: 20px;

  .head {
    background-color: rgba($color: $lightGreen, $alpha: 0.05);
    padding: 20px;
    color: rgba($color: $secondary, $alpha: 0.5);
    border-radius: 15px 15px 0px 0px;
  }
  .table-bottom {
    background-color: $white;
    padding: 20px;
    color: $secondary;
    border-radius: 0px 0px 15px 15px;
  }
}

.CircularProgressbar {
  height: 80px;
  width: 80px !important;
  min-width: 80px !important;
}

.point {
  cursor: pointer;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.btn-loader {
  &.spinner-border {
    width: 20px !important;
    height: 20px !important;
    vertical-align: bottom !important;
  }
}

.custom-scroll {
  height: 450px;
  overflow: auto;
  // margin: 0px 10px;
  padding: 10px;
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    // box-shadow: inset 0 0 5px grey;
    border-radius: 2px;
    border: 1px solid $white;
    background-color: $white;
  }

  &::-webkit-scrollbar-thumb {
    background: $primary;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $primary;
  }
}
.modal-custom-show {
  display: block;
  &::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
  }
  .modal-dialog {
    z-index: 1;
  }
  .close-icon {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }
  .inner {
    padding: 0;
    width: 100%;
    .units-input-say {
      width: 100%;
    }
    .degree-input-set {
      display: flex;
      align-items: center;
      button {
        padding: 10px;
        svg {
          width: 15px;
          height: 15px;
        }
      }
      span {
        white-space: nowrap;
        margin: 0 6px;
      }
    }
  }
}

.battery-per-show {
  display: flex;
  align-items: center;
  button {
    border: none;
    background: transparent;
    margin: 0 10px;
    img {
      border: none !important;
    }
  }
}

//common user list style -------------

.common-search-list {
  list-style: none;
  padding: 0;
  margin: 0;
  box-shadow: 0px 1px 10px #00000029;
  border-radius: 10px;
  position: absolute;
  top: 60px;
  background-color: $white;
  z-index: 4;
  width: 100%;
  max-height: 240px;
  overflow: auto;
  li {
    cursor: pointer;
    padding: 10px;
    border-bottom: 1px solid #ddd;
    &:last-child {
      border-bottom: none;
    }
  }
}

//custom tooltip style -----------
.custom-tooltip {
  position: relative;
  display: inline-block;
  &:hover {
    .tooltip-desc {
      display: block;
    }
  }
  svg {
    fill: $white;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  .tooltip-desc {
    background-color: $white;
    display: inline-block;
    border-radius: 10px;
    padding: 5px 10px;
    position: absolute;
    top: -8px;
    right: 34px;
    box-shadow: 0 1px 10px #00000061;
    display: none;
    .top-triangle {
      border-bottom: solid 10px $white;
      border-left: solid 10px transparent;
      border-right: solid 10px transparent;
      position: absolute;
      top: 18px;
      right: -15px;
      transform: rotate(90deg);
    }
    p {
      font-weight: 400;
      color: $black;
      margin: 0;
    }
  }
}
.switch {
  position: relative;
  display: inline-block;
  width: 79px;
  height: 26px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $danger;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: $sucess;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(55px);
}

/*------ ADDED CSS ---------*/
.slider:after {
  content: "OFF";
  color: white;
  display: block;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  font-size: 10px;
  font-family: Verdana, sans-serif;
}

input:checked + .slider:after {
  content: "ON";
}
.lock-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $sucess;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}

.lock-slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .lock-slider {
  background-color: $danger;
}

input:focus + .lock-slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .lock-slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(55px);
}

/*------ ADDED CSS ---------*/
.lock-slider:after {
  content: "UNLOCK";
  color: white;
  display: block;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  font-size: 10px;
  font-family: Verdana, sans-serif;
}

input:checked + .lock-slider:after {
  content: "LOCK";
}

.custom-toggle-btn {
  display: flex;
  align-items: center;
  background-color: $grey;
  border-radius: 100px;
  button {
    border-radius: 20px;
    padding: 5px 10px;
    width: 100%;
    color: $white;
    border: none;
    background-color: transparent;
    &.active {
      background-color: $primary;
    }
    &.inactive {
      background-color: $danger;
    }
  }
}

.action-btn {
  border-radius: 10px;
  padding: 6px 15px;
  border: none;
  margin-right: 10px;
  &.assign-btn {
    background-color: $primary;
    color: $white;
  }
  &.unassign-btn {
    background-color: $black;
    color: $white;
  }
  &.fix-btn {
    min-width: 100px;
  }
}

.edit-profile-select {
  position: relative;
  .input-change-image {
    position: absolute;
    bottom: 8px;
    right: 10px;
    width: 40px;
    height: 40px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border: 1px solid $black;
    background-color: $white;
    cursor: pointer;
    img {
      width: 100%;
    }
    input[type="file"],
    input[type="file"]::-webkit-file-upload-button {
      cursor: pointer;
    }
    input {
      opacity: 0;
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.tooltip-img {
  width: 20px;
  height: 20px;
}

.position-back {
  position: absolute;
  top: 30px;
  left: 30px;
}

.notification-btn {
  border-radius: 100px;
  width: 50px;
  min-width: 50px;
  height: 50px;
  padding: 0;
  position: relative;
  margin: 0 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  svg {
    width: 35px;
    height: 35px;
  }
  img {
    width: 50%;
  }
  .notification-show {
    width: 12px;
    height: 12px;
    border-radius: 100px;
    background-color: $danger;
    position: absolute;
    top: 9px;
    right: 11px;
    border: 1px solid $white;
  }
}

.action-btn-show {
  cursor: pointer;
  svg {
    width: 18px;
    &:hover {
      fill: $danger;
    }
  }
}

.infinite-custom-scroll {
  overflow-y: auto !important;
  overflow-x: hidden !important;
}

.header-search {
  display: flex;
  justify-content: flex-end;
}

//cursor pointer style --------------
.cursorA {
  cursor: pointer;
}

.svg-set-edit {
  display: flex;
  flex-flow: column;
  justify-content: center;
  svg {
    width: 40px;
    height: 40px;
  }
}

.button-edit-icon {
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: default !important;
  svg {
    width: 35px !important;
    height: 35px !important;
  }
}

.common-input-range {
  position: relative;
  .auth-msg {
    position: absolute;
    bottom: -25px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
}

.please-wait {
  height: calc(100vh - 180px);
  display: flex;
  align-items: center;
  justify-content: center;
  .please-wait-inner {
    text-align: center;
    img {
      margin-bottom: 10px;
      width: 40px;
      min-width: 40px;
    }
    p {
      font-size: 18px;
      margin-bottom: 0px;
      color: black;
    }
  }
}

.custom-badge-on {
  border-radius: 20px;
  background-color: $sucess;
  padding: 4px 15px;
  color: $white;
  font-size: 14px;
  letter-spacing: 1px;
}
.custom-badge-off {
  border-radius: 20px;
  background-color: $danger;
  padding: 4px 15px;
  color: $white;
  font-size: 14px;
  letter-spacing: 1px;
}

.switch-statement-show {
  p,
  span {
    font-weight: 600;
  }
}
.default {
  cursor: default !important;
}

.api-card {
  .theme-button {
    padding: 5px 15px 5px 10px;
    svg {
      width: 30px;
      min-width: 30px;
      height: 30px;
      fill: #000000;
    }
    span {
      margin-top: 2px;
    }
    &:hover {
      background-color: #000000;
      color: #fff;
      svg {
        fill: #fff;
      }
    }
  }
}

.demo-bulding-align-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 20px;
    height: 20px;
    object-fit: contain;
    margin: 10px 10px 0;
    cursor: pointer;
  }
}

.height-set-header {
  min-height: 48px;
}

.height-set-address {
  min-height: 59px;
}

.pointer-events-none {
  pointer-events: none;
}

.tooltip-img {
  margin-left: 10px;
}

//theme-modal
.theme-modal {
  .modal-dialog {
    .modal-content {
      .modal-header {
        position: relative;
        .modal-closer {
          position: absolute;
          top: 15px;
          right: 15px;
          .modal-closer-btn {
            margin: 0px;
            padding: 0px;
            border: 0px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: transparent;
            img {
              width: 18px;
              height: 18px;
              min-width: 18px;
            }
          }
        }
      }
    }
  }
}

//units-modal
.units-modal {
  .modal-dialog {
    .modal-content {
      .modal-body {
        max-height: 200px;
        overflow-y: auto;
      }
    }
  }
}

//units-list
.units-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  p {
    margin: 0px 0px 15px;
    padding: 0px 10px;
    color: $black;
    font-size: 14px;
    line-height: 18px;
    position: relative;
    cursor: pointer;
    &::after {
      content: "";
      position: absolute;
      top: 0px;
      right: 0px;
      height: 100%;
      width: 0.5px;
      background-color: #707070;
      opacity: 0.5;
    }
    &:nth-last-child(1) {
      &:after {
        display: none;
      }
    }
  }
}

.error-show-height {
  max-height: 200px;
  overflow: auto;
}

.ticket-icon {
  width: 24px;
  height: 24px;
  object-fit: contain;
}

.table-select {
  padding: 8px 12px;
  border-radius: 10px;
  text-transform: uppercase;
  &:focus-visible {
    border: 1px solid;
    box-shadow: none;
    outline: none;
  }
}

.view-detail-modal {
  .modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    h2 {
      min-width: 290px;
      font-size: 26px !important;
      color: $primary;
      font-weight: 600;
    }
    button {
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 100%;
      }
    }
    .ticket-create-position {
      position: absolute;
      top: 20px;
      right: 20px;
    }
    p {
      color: rgba($secondary, 0.4);
      font-family: "Product Sans Bold";
    }
  }
  .modal-body {
    .ticket-user-info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      p {
        span {
          color: $primary;
          font-family: "Product Sans Bold";
          margin-right: 10px;
        }
      }
    }
    .ticket-form {
      margin-bottom: 20px;
      .label-form {
        color: $primary;
        font-family: "Product Sans Bold";
      }
      p {
        word-break: break-word;
      }
    }
  }
}

.custom-searchbar {
  .header-search {
    .form-group {
      width: 100%;
    }
  }
}

.message-table {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  min-width: 300px;
  width: 300px;
}

.custom-select-multi {
  &.height-change {
    .css-13cymwt-control,
    .css-t3ipsp-control {
      padding: 6px 15px;
    }
  }
  .css-13cymwt-control,
  .css-t3ipsp-control {
    font-size: 18px;
    background-color: #ffffff;
    border-radius: 15px;
    padding: 2px 15px;
    outline: none;
    box-shadow: none;
    border: 1px solid #0d0d0d4d;
  }
  &.theme-select {
    background-color: rgba($color: $primary, $alpha: 0.1);
    color: $primary;
  }
  &.approve {
    background-color: $sucess;
    color: $white;
    option {
      background-color: $white;
    }
  }
  &.denied {
    background-color: $danger;
    color: $white;
    option {
      background-color: $white;
    }
  }
  &.pending {
    background-color: $grey;
    color: $white;
    option {
      background-color: $white;
    }
  }
  &.inprogress {
    background-color: $grey;
    color: $white;
    option {
      background-color: $white;
    }
  }
}

.margin-common-remove {
  .form-group {
    margin-bottom: 0;
    min-width: 200px;
    .form-select {
      &.yellow {
        border: 1px solid #ffb100;
        background-color: #ffb10033;
        color: $black;
      }
      &.green {
        border: 1px solid #06aa00;
        background-color: #06aa0033;
        color: $black;
      }
      &.red {
        border: 1px solid #e31f19;
        background-color: #e31f1933;
        color: $black;
      }
    }
  }
}

.infinite-scroll-component__outerdiv {
  width: 100%;
}

// .infinite-scroll-component  {
//   height: calc(100vh - 450px) !important;
// }

.date-comment-show {
  color: #979797;
  font-size: 12px;
}

.bb1 {
  border-bottom: 1px solid $grey;
}

.comment-height {
  max-height: 150px;
  overflow: auto;
}
.select-margin {
  select {
    border: solid 1px $primary !important;
  }
}

//action-btn
.action-btn {
  svg {
    width: 18px;
    cursor: pointer;
  }
}
.email-page {
  .atteched-file-btn {
    position: relative;
    display: inline-block;
    input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
    }
    .btn-inner {
      svg {
        fill: $black;
        width: 30px;
        height: 30px;
        min-width: 30px;
      }
      span {
        color: $black;
      }
    }
    input[type="file"]::-webkit-file-upload-button {
      /* chromes and blink button */
      cursor: pointer;
    }
  }
  .action-btn {
    .theme-button {
      min-width: 200px;
      font-size: 20px;
    }
  }

  .atteched-file-list {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
    margin-bottom: 20px;
    .atteched-file-item {
      display: flex;
      align-items: center;
      gap: 6px;
      border: 1px solid rgba($black, 0.3);
      padding: 6px;
      border-radius: 4px;
      width: fit-content;
      position: relative;
      .item-icon,
      svg {
        width: 30px;
        height: 30px;
        min-width: 30px;
      }
      span {
        color: $black;
        font-size: 14px;
        line-height: normal;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100px;
      }
      .close-item {
        position: absolute;
        top: -13px;
        right: -8px;
        background: $white;
        svg {
          width: 20px;
          height: 20px;
          min-width: 20px;
          opacity: 0.5;
          cursor: pointer;
        }
      }
    }
  }
}   

//light-control-range
.light-control-range{
  position: relative;
  padding: 0px 40px;
  margin-top: 20px;
  .light-status-icon{
    position: absolute;
    top: -5px;
    svg{
      width: 24px;
      height: 24px;
      min-width: 24px;
    }
    &.dim-icon{
      left: 0px;
    }
    &.bright-icon{
      right: 0px;
    }
  }
  .range-slider{
    input{
      width: 100%;
      accent-color: $primary;
    }
    .hue-picker {
      width: 100% !important;
    }
  }
}
.rangebar-box{
  margin-top: 5px;
  label{
    font-size: 14px;
    padding-bottom: 2px;
  }
}

