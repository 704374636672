@import "../../styles/Variable.scss";
.sidebar {
  background-color: $white;
  width: 300px;
  padding: 20px 0px;
  height: fit-content;
  min-height: 100vh;
  position: fixed;
  height: calc(100vh - 250px);
  overflow-y: auto;
  top: 0;
  left: 0;
  background: #ffffff 0% 0% no-repeat padding-box;
  text-align: left;
  .logo {
    padding: 20px;
    img {
      width: 150px;
    }
  }
  ul {
    padding: 0px;
    li {
      list-style: none;
      margin-right: 20px;
      margin-bottom: 10px;
      // padding: 20px;
      .select-sidebar {
        padding: 0 20px;
        display: flex;
        align-items: center;
      }
      .form-group {
        margin: 0;
        width: 100%;
        select {
          border: none;
          border-radius: 0;
          background-color: transparent;
          padding: 18px 0;
        }
      }
      h3 {
        color: $secondary;
        display: flex;
        align-items: center;
      }
      a {
        padding: 20px;
        display: block;
        position: relative;
        .text-pills {
          position: absolute;
          top: 18px;
          right: 15px;
          background-color: #0d0d0d19;
          color: $grey;
          border-radius: 10px;
          padding: 4px 12px;
          font-size: 14px;
        }
        // color: $secondary;
        // display: block;
      }

      svg {
        fill: $secondary;
        margin-right: 10px;
        height: 20px;
        width: 20px;
        min-width: 20px;
      }
      &:hover,
      &.active {
        background-color: $primary;
        border-radius: 0px 10px 10px 0px;
        h3 {
          color: $white;
        }
        svg {
          fill: $white;
        }
        // select {
        //   color: $white;
        // }
      }
    }
  }
}
.bottom-outer {
  padding-top: 40px;
  border-top: 2px solid rgba($color: $primary, $alpha: 0.3);
  margin: 0px 20px;

  .sidebar-bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: transparent
      linear-gradient(69deg, #179eff 0%, #00b1ff 53%, #38e3ff 100%) 0% 0%
      no-repeat padding-box;
    border-radius: 30px;
    padding: 20px;
    // margin: 10px;
    color: $white;
    text-align: center;
    position: relative;
    .sidebar-img-bottom {
      position: absolute;
      bottom: 0px;
      left: 0px;
    }
  }
}
